.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #FFFFFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #1D1D1F;
  font-family: 'Poppins', sans-serif;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --color-introduction: #ff595e;
  --color-education: #8ac926;
  --color-experience: #FFCA3A;
  --color-contact: #1982C4;
}

.underline-introduction {
  text-decoration: underline;
  text-decoration-color: var(--color-introduction);
}

.underline-education {
  text-decoration: underline;
  text-decoration-color: var(--color-education);
}

.underline-experience {
  text-decoration: underline;
  text-decoration-color: var(--color-experience);
}

.underline-contact {
  text-decoration: underline;
  text-decoration-color: var(--color-contact);
}

h4,p {
  text-align: left;
  margin: 10px;
}

.grid-margin {
  margin: 16px; /* Adjust the margin as needed */
}


.small-text {
  font-size: 20px; /* Adjust the size as needed */
  line-height: 1.5; /* Optional: sets line spacing */
  text-align: left;
  margin-left: 50px;
}

.x-small-text {
  font-size: 18px; /* Adjust the size as needed */
  line-height: 1.5; /* Optional: sets line spacing */
  text-align: left;
  margin-left: 50px;
}

.small-text-education {
  font-size: 20px; /* Adjust the size as needed */
  line-height: 1.5; /* Optional: sets line spacing */
  text-align: center;
}

.centre-text {
  text-align: center;
}

/* Media query for small screens (e.g., phones) */
@media (max-width: 768px) {
  .small-text {
    font-size: 16px; /* Smaller size for mobile */
    margin-left: 0;
  }

  .x-small-text {
    font-size: 14px; /* Smaller size for mobile */
    margin-left: 0;
  }

  .small-text-education {
    font-size: 16px; /* Adjust for education section on mobile */
  }
}

