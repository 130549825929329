body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-container {
  font-family: "Outfit", sans-serif;
}

a {
  color: #2F56B0;
  position: relative;
  text-decoration: none;
  transition: color .4s ease-out;
}

a:hover {
  color: #457DFB;
  text-decoration: none;
}

a::after {
  border-radius: 1em;
  border-top: .1em solid #2F56B0;
  content: "";
  position: absolute;
    right: 100%;
    bottom: .05em;
    left: 0;
  transition: right .4s cubic-bezier(0,.5,0,1),
              border-color .4s ease-out;
}

a:hover::after {
  border-color: #457DFB;
  right: 0;
}



